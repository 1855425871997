import React, { Component } from 'react';
class Footer extends Component {
    render() {
        return (
        <footer>
            <p>&copy;2019 ∙ Jasmine Carbone</p>
        </footer>
        );
    }
}
export default Footer;