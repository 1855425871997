import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
        <div className="wrapper">
            <header>
                <div>
                    <p>Welcome to</p>
                    <h1>Skate Date&nbsp;&nbsp;</h1>
                </div>
            </header>

        </div>
        
        );
    }
}
export default Header;